import React, { PureComponent } from 'react'
import { batch, connect } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
import { Trashcan } from './../../../Icons/Icons';
import { BLOCKED_EXTENSIONS } from './Document';

const mapStateToProps = (state, ownProps) => {
  let engSwid = ownProps.SaveEngineSwid;
  let saveDataKey = null;

  if (Array.isArray(state.blu_Scene)) {
    let engines = state.blu_Scene.find(x => x.Name === 'Engines');
    let eng = engines ? engines.Widgets.find(x => x.SceneWidgetId == engSwid) : null;
    if (eng) {
      let saveDataParam = eng.Parameters.find(x => x.ParameterName === 'SaveData');
      saveDataKey = saveDataParam ? saveDataParam.ParameterValue : null;
    }
  }

  return {
    SaveData: saveDataKey ? state[saveDataKey] : null,
    SaveDataKey: saveDataKey,
    SaveEngineSwid: engSwid,
    BeforeSaveId: state.dbo_BeforeSaveId,
    BeforeSaveSwid: state.dbo_BeforeSaveSwid,
    SaveId: state.dbo_SaveId,
    Preview: state['preview' + ownProps.SWID],
    PreviewKey: 'preview' + ownProps.SWID,
    CancelSource: state[ownProps.CancelSource],
    GlobalReadOnly: state.GlobalReadOnly
  };
}

let _waitingToSave = {};

export class SingleFile extends PureComponent {
  state = {
    InvalidUser: false,
    InvalidDate: false,
    FullScreenImage: false,
    ImportingPdf: false,
    ShowPreview: false,
    EntityPreviewLoaded: false
  }

  componentDidMount() {
    this.onUpdate();
  }

  componentDidUpdate(prev) {
    this.onUpdate(prev);
  }

  onUpdate = (prev) => {
    if (prev) {
      if (_waitingToSave[this.props.SWID]) {
        if (this.props.SaveId != prev.SaveId) {
          if (this.props.CurrentEntity.EntityId < 0) {
            _waitingToSave[this.props.SWID] = 'pending entity change';
          } else {
            this.save();
            _waitingToSave[this.props.SWID] = false;
          }
        }
        if (_waitingToSave[this.props.SWID] === 'pending entity change' && this.props.CurrentEntity.EntityId > 0) {
          this.save();
          _waitingToSave[this.props.SWID] = false;
        }
      } else {
        let curEnt = helpers.entId(this.props.CurrentEntity);
        let prevEnt = helpers.entId(prev.CurrentEntity);
        if (curEnt != prevEnt) {
          this.resetFile();
        }

        if (prev.BeforeSaveId !== this.props.BeforeSaveId && this.props.BeforeSaveSwid == this.props.SaveEngineSwid) {
          if (this.props.WaitForEntitySave) {
            _waitingToSave[this.props.SWID] = true;
          } else {
            this.save();
          }
        }

        if (prev.CancelSource && this.props.CancelSource && prev.CancelSource.ClickId !== this.props.CancelSource.ClickId) {
          this.props.getDocuments();
        }
      }

      if (!this.state.EntityPreviewLoaded && this.props.model && this.props.CurrentEntity && this.props.model.EntityId == this.props.CurrentEntity.EntityId) {
        this.showPreview();
      }
    }
  }

  resetFile = () => {
    document.getElementById('DocumentPicker').value = '';
    this.props.dispatch(actions.UpdateProp({
      Key: this.props.PreviewKey,
      Value: null
    }));
    this.setState({ ShowPreview: false, EntityPreviewLoaded: false });
  }

  setProp = (e, prop) => {
    this.props.dispatch(actions.UpdateDocument({
      ...this.props.model,
      DocumentsKey: this.props.DocumentsKey,
      [prop]: e.target.value,
      Dirty: true
    }));
  }

  attachFile = () => {
    if (this.props.GlobalReadOnly)
      return;

    document.getElementById('DocumentPicker').click();
  }

  dirtySaveQueue = () => {
    this.props.dispatch(actions.UpdateProp({
      Key: this.props.SaveDataKey,
      Value: [...this.props.SaveData, { IsPlaceholder: true }]
    }));
  }

  fileAttached = (e) => {
    let target = e.target;

    this.dirtySaveQueue();

    this.setState({ ShowPreview: true }, () => {
      if (!this.props.CurrentEntity)
        return;

      let file = target.files[0];
      if (!file)
        return;

      const extension = file?.name?.split('.')?.pop();
      if(extension && BLOCKED_EXTENSIONS.includes(extension)) {
        this.props.dispatch(
          actions.UpdateProp({
            Key: "blu_Dialog",
            Value: {
              Title: "Unsupported File Type",
              Message: (
                <p style={{ fontSize: "1.1rem", marginTop: "20px" }}>
                  This is an unsupported file type.
                </p>
              ),
            },
          })
        );
        
        return;
      }

      if (file.type.includes('image')) {
        let previewImageDims = { width: 350, height: 200 };
        let maxDims = { width: 1920, height: 1080 };
        this.getResizedImage(file, previewImageDims, (preview) => {
          if (file.width > previewImageDims.width || file.height > previewImageDims.height) {
            this.getResizedImage(file, maxDims, (result) => {
              this.setFormData(file, [result, preview]);
              this.setPreviewUrl(URL.createObjectURL(preview));
            });
          } else {
            this.setFormData(file, [preview]);
            this.setPreviewUrl(URL.createObjectURL(preview));
          }
        });
      } else {
        this.showExtensionPreview(file.name.split('.').pop());
        this.setFormData(file);
      }
    });
  }

  setFormData = (file, blob = null) => {
    let formData = new FormData();
    formData.append(file.name + '_l', blob ? blob[0] : file, file.name);
    formData.append("FileType", file.type);

    this.props.model.DisplayName = file.name;

    if (blob && blob[1]) {
      formData.append(file.name + '_s', blob ? blob[1] : file, file.name);
    }

    this.props.dispatch(actions.UpdateDocument({
      ...this.props.model,
      DocumentsKey: this.props.DocumentsKey,
      FormData: formData,
      Dirty: true
    }));
  }

  getResizedImage = (file, maxDims, callback) => {
    let canvas = document.createElement('canvas');
    let fr = new FileReader();
    fr.readAsDataURL(file);
    fr.onload = () => {
      let img = new Image();
      img.src = fr.result;
      img.onload = () => {
        file.width = img.width;
        file.height = img.height;
        let dims = helpers.resizeDimensions(img.width, img.height, maxDims.width, maxDims.height);
        canvas.width = dims.width;
        canvas.height = dims.height;
        let ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, dims.width, dims.height);
        canvas.toBlob(callback, file.type);
      };
    }
  }

  showExtensionPreview = (extension) => {
    let img = document.createElement('img');
    img.onerror = () => {
      img.src = `https://beehivetilcache.blob.core.windows.net/staticassets/FileIcons/unknown.svg`;
      this.setPreviewUrl(img.src);
    }
    img.src = `https://beehivetilcache.blob.core.windows.net/staticassets/FileIcons/${extension}.svg`;
    this.setPreviewUrl(img.src)
  }

  save = () => {
    if (!this.props.model.Dirty)
      return;

    let entity = helpers.getEntityIds(this.props.CurrentEntity);
    let formData = this.props.model.FormData;
    if (!formData) {
      formData = new FormData();
      formData.append("FileType", this.props.model.FileType || '');
    }

    formData.append("DocumentId", this.props.model.DocumentId);
    formData.append("Extension", this.props.model.Extension || '');
    formData.append("EntityTypeId", entity.EntityTypeId);
    formData.append("EntityId", entity.EntityId);
    formData.append("SaveTypeId", 1);
    formData.append("DocTypeId", 1);
    formData.append("DocDate", this.props.model.DocDate);
    formData.append("IsDisplay", this.props.model.IsDisplay);
    formData.append("IsPublic", this.props.model.IsPublic);
    formData.append("IsGroup", this.props.model.IsGroup);
    formData.append("SourcePath", "");
    formData.append("Comment", this.props.model.Comment || '');
    formData.append("AssociatedWith", this.props.model.AssociatedWith);
    formData.append("IsDeleted", this.props.model.IsDeleted);
    formData.append("DisplayName", this.props.model.DisplayName || '');

    actions.ApiSendData('Document/Save', formData, (result) => {
      this.setDocuments(result.Documents || [], result.SavedDocId);
    });
  }

  setDocuments = (items, activeDocId = null) => {
    let entity = helpers.getEntityIds(this.props.CurrentEntity);
    items.forEach(item => item.Active = item.DocumentId === activeDocId);
    this.props.dispatch(actions.SetEntityDocuments({
      EntityId: entity.EntityId,
      EntityTypeId: entity.EntityTypeId,
      DocumentsKey: this.props.DocumentsKey,
      Items: [...items]
    }));
  }

  downloadFile = () => {
    if (!this.props.model.Extension)
      return;

    let isImage = this.props.model.FileType.includes('image');
    let filename = this.props.model.DocumentId + (isImage ? '_l' : '') + '.' + this.props.model.Extension;

    actions.ApiGet('betty/' + filename, filename, (result) => {
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      let url = window.URL.createObjectURL(result);
      a.href = url;
      a.download = this.props.model.DisplayName || filename;
      a.click();
      window.URL.revokeObjectURL(url);
    });
  }

  closeFullScreenPhoto = () => {
    document.body.style.visibility = 'visible';
    document.body.classList.remove('full-screen');
    this.setState({ FullScreenImage: false });
  }

  showPreview = () => {
    if (!this.props.model.Extension || this.props.model.IsDeleted)
      return;

    this.setState({ EntityPreviewLoaded: true });

    if (this.props.model.FileType.includes('image')) {
      this.getPreviewImage();
    } else {
      this.showExtensionPreview(this.props.model.Extension);
    }
  }

  setPreviewUrl = (url) => {
    this.props.dispatch(actions.UpdateProp({
      Key: this.props.PreviewKey,
      Value: url
    }));
  }

  getPreviewImage = (small = true) => {
    let filename = this.props.model.DocumentId + (small ? '_s' : '_l') + '.' + this.props.model.Extension;

    actions.ApiGet('betty/' + filename, filename, (result) => {
      if (!result && small)
        this.getPreviewImage(false);
      if (!result) {
        return;
      }

      this.setPreviewUrl(URL.createObjectURL(result));
    });
  }

  trashPhoto = () => {
    if (this.props.GlobalReadOnly)
      return;

    batch(() => {
      this.resetFile();

      if (!this.props.model.IsNew && this.props.SaveData) {
        this.props.model.IsDeleted = true;

        this.props.dispatch(actions.UpdateProp({
          Key: this.props.SaveDataKey,
          Value: [...this.props.SaveData, {
            Column: 'IsDeleted',
            Table: 'doc.Document',
            Value: 1,
            IsBaseTable: 1,
            Id: this.props.model.DocumentId
          }]
        }));
      }
    });
  }

  render() {
    let fileAttached = false;
    let model = this.props.model;

    if (model) {
      fileAttached = (model.Extension || model.DisplayName)
    }

    return (
      <div className="doc-edit-container single-file-container">
        <div className="doc-edit">
          <div className="left-pane">
            <div className={"attach-preview" + ((fileAttached || this.state.ShowPreview) ? ' preview-mode' : ' attach-mode')}>
              {
                <div className={"add-attachment " + (fileAttached ? "attached" : "")} onClick={this.attachFile}>
                  <div>
                    <div>Add</div>
                    <div>Attachment</div>
                  </div>
                  <input type="file" name="file" id="DocumentPicker" style={{ display: 'none' }} onChange={this.fileAttached} />
                </div>
              }
              {
                <div className={"single-file-preview preview-box" + (this.state.ShowPreview ? ' display' : '')} onClick={this.downloadFile}>
                  <img src={this.props.Preview} />
                </div>
              }
              <div className="trash-photo">
                <div onClick={this.trashPhoto}>
                  <Trashcan />
                </div>
              </div>
            </div>
          </div>
        </div>
        {
          this.state.FullScreenImage &&
          <div className="full-screen-rel">
            <div className="full-screen-abs">
              <div id="FullScreenImage">
                <div className="close-btn" onClick={this.closeFullScreenPhoto}>
                  <div className="cross-vertical"></div>
                  <div className="cross-horizontal"></div>
                </div>
                <div className="image-container"></div>
              </div>
            </div>
          </div>
        }
      </div>
    )
  }
}

export default connect(mapStateToProps)(SingleFile);