import React from "react";
import { batch, connect } from "react-redux";
import * as actions from "../../../../../Stores/Actions/actions";
import * as helpers from "../../../../../Utils/Helpers";

const mapStateToProps = (state, ownProps) => {
  return {
    CrewId: state.ent_Selected.EntityId,
    SaveData: state.dbo_SaveData,
  };
};

export class CrewMgt extends React.PureComponent {
  componentDidMount() {
    console.log(this.props);

    let cdata = this.props.Trigger;
    let type = cdata.Type;

    let id = null;
    let insertKey = null;
    let enable = null;
    let crewMgtId = cdata.UserId + cdata.Type;

    if (type === "Member") {
      id = cdata.LinkIdMember;
      insertKey = id ? null : helpers.getInsertKey();
      enable = !cdata.IsMember;
    }

    if (type === "Admin") {
      id = cdata.LinkIdAdmin;
      insertKey = id ? null : helpers.getInsertKey();
      enable = !cdata.IsAdmin;
    }

    let oldDeleted = this.props.SaveData.find(
      (x) => x.CrewMgtId === crewMgtId && x.Column === "IsDeleted"
    );
    if (oldDeleted) {
      enable = oldDeleted.Value;
    }

    let cdataFilter = this.props.SaveData.filter(
      (x) => x.CrewMgtId !== crewMgtId
    );
    this.props.dispatch(
      actions.UpdateProp({
        Key: "dbo_SaveData",
        Value: cdataFilter,
      })
    );

    this.flipBlue(cdata.UserId + "-" + cdata.Type.toLowerCase());

    if (enable || id) {
      batch(() => {
        //userid
        this.props.dispatch(
          actions.AddSaveData({
            Id: id,
            InsertKey: insertKey,
            Table: "usr.CrewUserLink",
            Column: "UserId",
            Value: cdata.UserId,
            IsEventData: false,
            SaveQueue: "dbo_SaveData",
            IsBaseTable: true,
            CrewMgtId: crewMgtId,
          })
        );

        //crewId
        this.props.dispatch(
          actions.AddSaveData({
            Id: id,
            InsertKey: insertKey,
            Table: "usr.CrewUserLink",
            Column: "CrewId",
            Value: this.props.CrewId,
            IsEventData: false,
            SaveQueue: "dbo_SaveData",
            IsBaseTable: true,
            CrewMgtId: crewMgtId,
          })
        );

        //linktype
        this.props.dispatch(
          actions.AddSaveData({
            Id: id,
            InsertKey: insertKey,
            Table: "usr.CrewUserLink",
            Column: "LinkType",
            Value: cdata.Type,
            IsEventData: false,
            SaveQueue: "dbo_SaveData",
            IsBaseTable: true,
            CrewMgtId: crewMgtId,
          })
        );

        //isdeleted
        this.props.dispatch(
          actions.AddSaveData({
            Id: id,
            InsertKey: insertKey,
            Table: "usr.CrewUserLink",
            Column: "IsDeleted",
            Value: !enable,
            IsEventData: false,
            SaveQueue: "dbo_SaveData",
            IsBaseTable: true,
            CrewMgtId: crewMgtId,
          })
        );
      });
    }
  }

  flipBlue = (id) => {
    let element = document.getElementsByClassName(id);
    if (element && element[0]) {
      if (element[0].classList.contains("active")) {
        element[0].classList.remove("active");
      } else {
        element[0].classList.add("active");
      }
    }
  };

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(CrewMgt);
