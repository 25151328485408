import React, { PureComponent } from 'react'
import { connect } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
import { LoadingDotSpinner } from '../../../Icons/Icons';
import * as Azure from '@azure/storage-blob';
import { BLOCKED_EXTENSIONS } from './Document';

const mapStateToProps = (state, ownProps) => {
  let pdfImport = false;
  if (ownProps.CurrentEntity && state.met_EntityMetadata) {
    let cfg = state.met_EntityMetadata.ConfigurationSettings;
    let importSetting = cfg.find(x => x.Key === 'PDFAttachmentImport');

    if (importSetting) {
      let importIds = importSetting.Value.split(',').filter(x => x !== '').map(x => Number(x.trim()));
      if (importIds.includes(ownProps.CurrentEntity.EntityTypeId)) {
        pdfImport = true;
      }
    }
  }

  return {
    Cancel: state['cancel_' + ownProps.SWID],
    Save: state['save_' + ownProps.SWID],
    Uploading: state['uploading_' + ownProps.SWID],
    SWID: ownProps.SWID,
    UserList: state.met_EntityMetadata.UserList,
    AllowPdfImport: pdfImport,
    UserId: state.CurrentUser.Id,
    BlobSAS: state.BlobSAS
  };
}

let _savesPending = 0;

export class EditItem extends PureComponent {
  savePending = false;

  state = {
    InvalidUser: false,
    InvalidDate: false,
    FullScreenImage: false,
    ImportingPdf: false,
    HyperlinkMode: false
  }

  componentDidMount() {
    _savesPending = 0;
  }

  componentDidUpdate() {
    if (this.props.Cancel) {
      this.cancel();
      this.props.dispatch(actions.UpdateProp({
        Key: 'cancel_' + this.props.SWID,
        Value: null
      }));
    }
    if (this.props.Save) {
      this.save();
      this.props.dispatch(actions.UpdateProp({
        Key: 'save_' + this.props.SWID,
        Value: null
      }));
    }
    if (this.props.model && !this.props.model.saveFunc) {
      this.props.model.saveFunc = this.save;
    }
  }

  toggleProp = (name) => {
    this.props.dispatch(actions.UpdateDocument({
      ...this.props.model,
      DocumentsKey: this.props.DocumentsKey,
      [name]: !this.props.model[name],
      Dirty: true
    }));
  }

  componentDidMount() {
    if (!this.props.model.IsNew)
      this.showPreview();

    if (this.props.model.IsNew && this.props.model.reloadNewFile)
      this.props.model.reloadNewFile();

    if (this.props.model.FileType === 'hyperlink')
      this.setState({ HyperlinkMode: true });

    this.hyperlinkInput = React.createRef();
  }

  setProp = (e, prop) => {
    _savesPending = 0;
    this.props.dirtySaveQueue();

    this.props.dispatch(actions.UpdateDocument({
      ...this.props.model,
      DocumentsKey: this.props.DocumentsKey,
      [prop]: e.target.value,
      Dirty: true
    }));
  }

  attachFile = () => {
    document.getElementById('DocumentPicker').click();
    this.setState({ HideHyperlink: true });
  }

  dropFile = (e) => {
    e.preventDefault();
    e.currentTarget.style.boxShadow = 'none';
    this.setState({ HideHyperlink: true });
    this.fileAttached(e);
  }

  onDragOver = (e) => {
    e.currentTarget.style.boxShadow = 'inset 0 -2em 2em 1em rgba(255, 255, 255, 0.7), 0 0 0 2px rgb(0, 0, 0)';
    e.preventDefault();
  }

  onDragLeave = (e) => {
    e.preventDefault();
    e.currentTarget.style.boxShadow = 'none';
  }

  fileAttached = (e) => {
    if (!this.props.CurrentEntity)
      return;

    let file;
    if (e.target.files) {
      file = e.target.files[0];
    } else if (e.dataTransfer.files) {
      file = e.dataTransfer.files[0];
    } else {
      return;
    }
    
    if(!file) return;

    const extension = file?.name?.split('.')?.pop();
    if(extension && BLOCKED_EXTENSIONS.includes(extension)) {
      this.props.dispatch(
        actions.UpdateProp({
          Key: "blu_Dialog",
          Value: {
            Title: "Unsupported File Type",
            Message: (
              <p style={{ fontSize: "1.1rem", marginTop: "20px" }}>
                This is an unsupported file type.
              </p>
            ),
          },
        })
      );
      
      return;
    }

    this.props.model.reloadNewFile = () => { this.setNewFile(file) };
    this.setNewFile(file);
  }

  setNewFile = (file) => {
    if (file.type.includes('image')) {
      let previewImageDims = { width: 350, height: 200 };
      let maxDims = { width: 1920, height: 1080 };
      this.getResizedImage(file, previewImageDims, (preview) => {
        if (file.width > previewImageDims.width || file.height > previewImageDims.height) {
          this.getResizedImage(file, maxDims, (result) => {
            this.setFormData(file, [result, preview]);
          });
        } else {
          this.setFormData(file, [preview]);
        }
      });
    } else {
      this.showExtensionPreview(file.name.split('.').pop());
      this.setFormData(file);
    }
  }

  setFormData = (file, blob = null) => {
    _savesPending = 0;
    if (this.props.model.formDataSet)
      return;

    let formData = new FormData();

    formData.append('IsImage', (file.type || '').toLowerCase().includes('image'));
    formData.append("FileType", file.type);
    formData.append('FileName', file.name);

    this.props.model.DisplayName = file.name;

    formData.append('File0', blob ? blob[0] : file, file.name);

    if (blob && blob[1]) {
      formData.append('File1', blob ? blob[1] : file, file.name);
    }

    this.props.model.formDataSet = true;

    this.props.dispatch(actions.UpdateDocument({
      ...this.props.model,
      DocumentsKey: this.props.DocumentsKey,
      FormData: formData,
      Dirty: true
    }));
  }

  getResizedImage = (file, maxDims, callback) => {
    let canvas = document.createElement('canvas');
    let fr = new FileReader();
    fr.readAsDataURL(file);
    fr.onload = () => {
      let img = new Image();
      img.src = fr.result;
      img.onload = () => {
        file.width = img.width;
        file.height = img.height;
        let dims = helpers.resizeDimensions(img.width, img.height, maxDims.width, maxDims.height);
        canvas.width = dims.width;
        canvas.height = dims.height;
        let ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, dims.width, dims.height);
        canvas.toBlob(callback, file.type);
        helpers.showImagePreview(img, document.getElementsByClassName('preview-box')[0]);
      };
    }
  }

  showExtensionPreview = (extension) => {
    let img = document.createElement('img');
    img.onerror = () => {
      img.src = `https://beehivetilcache.blob.core.windows.net/staticassets/FileIcons/unknown.svg`;
    }
    img.src = `https://beehivetilcache.blob.core.windows.net/staticassets/FileIcons/${extension}.svg`;
    let preview = document.getElementsByClassName('preview-box')[0];
    preview.innerHTML = '';
    img.width = preview.clientWidth;
    img.height = preview.clientHeight;
    preview.appendChild(img);
  }

  formValid = () => {
    let valid = true;
    if (!this.props.model.DocDate) {
      this.setState({ InvalidDate: true });
      valid = false;
    }
    if (!this.props.model.AssociatedWith) {
      this.setState({ InvalidUser: true });
      valid = false;
    }
    return valid;
  }

  getBlobContainerClient = () => {
    let blobServiceClient = new Azure.BlobServiceClient(this.props.BlobSAS.Key);
    let containerName = this.props.BlobSAS.Database.replace(/_/g, '').toLowerCase();
    return blobServiceClient.getContainerClient(containerName);
  }

  save = (entityChangeMap, callbackModel = null) => {

    let model = callbackModel || this.props.model;

    if (!model.Dirty || this.savePending)
      return;

    let entity = helpers.getEntityIds(this.props.CurrentEntity);
    if (entityChangeMap && entityChangeMap.old && entityChangeMap.new) {
      if (entityChangeMap.old.EntityTypeId === entityChangeMap.new.EntityTypeId) {
        entity = helpers.getEntityIds(entityChangeMap.new);
      }
    }

    let formData = model.FormData;
    if (!formData) {
      formData = new FormData();
      formData.append("FileType", model.FileType || '');
    }

    if (!this.formValid())
      return;

    let file0 = formData.get('File0');
    let file1 = formData.get('File1');

    let extension = '';
    let fileType = formData.get('FileType');
    if (file0) {
      let fileName = formData.get('FileName');
      if (fileName) {
        extension = fileName.slice(fileName.lastIndexOf('.') + 1, fileName.length);
      }
    }


    let docType = 1;
    if (this.state.HyperlinkMode) {
      docType = 2;
    }

    let docFormData = new FormData();

    docFormData.append("DocumentId", model.DocumentId);
    docFormData.append("Extension", extension || model.Extension || '');
    docFormData.append("EntityTypeId", entity.EntityTypeId);
    docFormData.append("EntityId", entity.EntityId);
    docFormData.append("SaveTypeId", 1);
    docFormData.append("DocTypeId", docType);
    docFormData.append("DocDate", model.DocDate);
    docFormData.append("IsDisplay", model.IsDisplay);
    docFormData.append("IsPublic", model.IsPublic);
    docFormData.append("IsGroup", model.IsGroup);
    docFormData.append("SourcePath", "");
    docFormData.append("Comment", model.Comment || '');
    docFormData.append("AssociatedWith", model.AssociatedWith);
    docFormData.append("IsDeleted", model.IsDeleted);
    docFormData.append("DisplayName", docType === 2 ? model.Hyperlink : model.DisplayName || '');
    docFormData.append("FileType", docType === 2 ? 'hyperlink' : fileType || '');
    docFormData.append('FileName', docType === 2 ? model.Hyperlink : formData.get('FileName') || '');

    this.savePending = true;
    let refreshId = helpers.entId(this.props.CurrentEntity);
    _savesPending++;
    actions.ApiSendData('Document/CreateDocument', docFormData, (result) => {
      _savesPending--;
      let containerClient = this.getBlobContainerClient();

      if (file0) {
        let isImage = helpers.stringToBool(formData.get('IsImage'));
        let oploadOptions = { blobHTTPHeaders: { blobContentType: fileType || 'application/octet-stream' } };

        const blockBlobClient = containerClient.getBlockBlobClient(isImage ? result.SavedDocId + '_l.' + extension : result.SavedDocId + '.' + extension);
        this.props.dispatch(actions.UpdateProp({
          Key: 'uploading_' + this.props.SWID,
          Value: true
        }));
        blockBlobClient.uploadBrowserData(file0, oploadOptions).then(x => {
          this.savePending = false;
          if (result) {
            if (helpers.entId(this.props.CurrentEntity) === refreshId && _savesPending === 0) {
              // this.setDocuments(result.Documents || [], result.SavedDocId);
              this.props.RefreshDocuments();
            }
          }
        }).finally(x => {
          this.props.dispatch(actions.UpdateProp({
            Key: 'uploading_' + this.props.SWID,
            Value: false
          }));
        });
        if (file1) {
          const blockBlobClient2 = containerClient.getBlockBlobClient(isImage ? result.SavedDocId + '_s.' + extension : result.SavedDocId + '.' + extension);
          blockBlobClient2.uploadBrowserData(file1, oploadOptions);
        }

      } else {
        this.savePending = false;
        if (_savesPending === 0) {
          // this.setDocuments(result.Documents || [], result.SavedDocId);
          this.props.RefreshDocuments();
        }
      }
    });
  }

  cancel = () => {
    if (!this.props.model.Dirty)
      return;

    this.reloadDocuments();
  }

  delete = () => {
    if (this.props.model.IsNew)
      return;

    this.props.model.IsDeleted = true;
    this.props.model.Dirty = true;
    this.save();
  }

  reloadDocuments = () => {
    let entity = helpers.getEntityIds(this.props.CurrentEntity);
    actions.ApiRequest('Document/GetDocuments', entity, (result) => {
      this.setDocuments(result);
    });
  }

  setDocuments = (items, activeDocId = null) => {
    let entity = helpers.getEntityIds(this.props.CurrentEntity);
    items.forEach(item => item.Active = item.DocumentId === activeDocId);
    this.props.dispatch(actions.SetEntityDocuments({
      EntityId: entity.EntityId,
      EntityTypeId: entity.EntityTypeId,
      DocumentsKey: this.props.DocumentsKey,
      Items: [...items]
    }));
  }

  downloadFile = async () => {
    if (!this.props.model.Extension || this.state.HyperlinkMode)
      return;

    if (window.IsMobile) {
      this.fullScreenPhoto();
    } else {
      let isImage = this.props.model.FileType.includes('image');
      let filename = this.props.model.DocumentId + (isImage ? '_l' : '') + '.' + this.props.model.Extension;

      if (isImage) {
        this.fullScreenPhoto();
      } else {


        let containerClient = this.getBlobContainerClient();
        let blobClient = containerClient.getBlobClient(filename);

        const downloadBlockBlobResponse = await blobClient.download();
        const blobSrc = await downloadBlockBlobResponse.blobBody;

        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        let url = window.URL.createObjectURL(blobSrc);
        a.href = url;
        a.download = this.props.model.DisplayName || filename;
        a.click();
        window.setTimeout(() => {
          window.URL.revokeObjectURL(url);
        }, 200);

      }
    }
  }

  fullScreenPhoto = async () => {
    let isImage = this.props.model.FileType.includes('image');
    if (!isImage)
      return;

    let filename = this.props.model.DocumentId + '_l.' + this.props.model.Extension;

    let containerClient = this.getBlobContainerClient();
    let blobClient = containerClient.getBlobClient(filename);

    const downloadBlockBlobResponse = await blobClient.download();
    const blobSrc = await downloadBlockBlobResponse.blobBody;

    this.setState({ FullScreenImage: true });
    let img = new Image();
    img.src = URL.createObjectURL(blobSrc);
    document.body.style.visibility = 'hidden';
    document.body.classList.add('full-screen');
    helpers.showImagePreview(img, document.querySelector('#FullScreenImage .image-container'));

  }

  closeFullScreenPhoto = () => {
    document.body.style.visibility = 'visible';
    document.body.classList.remove('full-screen');
    this.setState({ FullScreenImage: false });
  }

  showPreview = () => {
    if (!this.props.model.Extension)
      return;

    if (this.props.model.FileType.includes('image')) {
      this.getPreviewImage();
    } else {
      this.showExtensionPreview(this.props.model.Extension);
    }
  }

  getPreviewImage = async (small = true) => {
    let filename = this.props.model.DocumentId + (small ? '_s' : '_l') + '.' + this.props.model.Extension;

    let containerClient = this.getBlobContainerClient();
    let blobClient = containerClient.getBlobClient(filename);
    let fileExists = await blobClient.exists();

    if (fileExists) {
      const downloadBlockBlobResponse = await blobClient.download();
      const blobSrc = await downloadBlockBlobResponse.blobBody;

      let img = new Image();
      img.src = URL.createObjectURL(blobSrc);
      helpers.showImagePreview(img, document.getElementsByClassName('preview-box')[0]);

    } else if (small) {
      this.getPreviewImage(false)
    }
  }

  importPdfData = () => {
    if (this.state.ImportingPdf || this.props.model.Extension !== 'pdf')
      return;

    this.setState({ ImportingPdf: true });
    actions.ApiRequest('Document/PdfImport', { DocumentId: this.props.model.DocumentId }, (result) => {
      this.setState({ ImportingPdf: false });
      this.props.dispatch(actions.UpdateProp({
        Key: 'ent_RefreshProps',
        Value: {}
      }));
    });
  }

  createHyperlink = () => {
    this.setState({ HyperlinkMode: true }, () => {
      if (this.hyperlinkInput && this.hyperlinkInput.current) {
        this.hyperlinkInput.current.focus();
      }
    });
  }

  render() {
    return (
      <div className="doc-edit-container">
        <div className="doc-edit">
          <div className="left-pane">
            <div className="button-bar">
              <div className={"doc-btn " + (this.props.model.IsDisplay ? "highlight" : "")} onClick={() => { this.toggleProp('IsDisplay') }} >
                <div>Display</div>
              </div>
              <div className={"doc-btn " + (this.props.model.IsPublic ? "highlight" : "")} onClick={() => { this.toggleProp('IsPublic') }} >
                <div>Public</div>
              </div>
              <div className={"doc-btn " + (this.props.model.IsGroup ? "highlight" : "")} onClick={() => { this.toggleProp('IsGroup') }} >
                <div>Group</div>
              </div>
            </div>
            <div className="attach-preview">
              {!this.state.HyperlinkMode &&
                <div className={"add-attachment " + (this.props.model.Extension ? "attached" : "")} onClick={this.attachFile} onDrop={this.dropFile} onDragOver={this.onDragOver} onDragLeave={this.onDragLeave}>
                  {
                    this.props.model.Extension ? <div>Attached</div> : <div><div>Drop or Browse</div><div>Attachment</div></div>
                  }
                  <input type="file" name="file" id="DocumentPicker" style={{ display: 'none' }} onChange={this.fileAttached} />
                </div>
              }
              {this.state.HyperlinkMode &&
                <div className="hyperlink-link">
                  <a href={((this.props.model.Hyperlink || '').startsWith('htt') ? '' : '//') + this.props.model.Hyperlink} target="_blank">Open Link</a>
                </div>
              }
              {<div className="preview-box" onClick={this.downloadFile}>
                {!this.state.HyperlinkMode && <div></div>}
                {this.state.HyperlinkMode && <div className="hyperlink-box">
                  <div className="hyperlink-label">Link URL</div>
                  <input ref={this.hyperlinkInput} type="text" value={this.props.model.Hyperlink || ''} onChange={(e) => { this.setProp(e, 'Hyperlink') }} />
                </div>}
              </div>}
            </div>
            {!this.state.HyperlinkMode && !this.props.model.Extension && !this.state.HideHyperlink &&
              <div className="hyperlink-preview">
                <div className="create-hyperlink" onClick={this.createHyperlink}><div>Create Hyperlink</div></div>
              </div>
            }
          </div>
          <div className="right-pane">
            {this.props.model.DisplayName && <div className="display-name text-ellipsis-truncate"><div>{this.props.model.DisplayName}</div></div>}
            <div className="user-date">
              <input className={"doc-date " + (this.state.InvalidDate ? "invalid" : "")} type="date" value={this.props.model.DocDate} onChange={(e) => { this.setProp(e, 'DocDate') }} />
              <div className={"doc-user " + (this.state.InvalidUser ? "invalid" : "")}>
                <select value={this.props.model.AssociatedWith} onChange={(e) => { this.setProp(e, 'AssociatedWith') }}>
                  {this.props.UserList.map((x, idx) => {
                    if(this.props.CurrentEntity.TenantId && this.props.CurrentEntity.TenantId == x.TenantId) {
                      return <option key={idx} value={x.UserId}>{x.Name}</option>
                    }
                  })}
                </select>
              </div>
            </div>
            <div className="doc-comment">
              <textarea value={this.props.model.Comment || ''} onChange={(e) => { this.setProp(e, 'Comment') }} />
            </div>
          </div>
        </div>
        <div className="attach-save-cancel">
          {!this.props.SaveQueue && <div className={"doc-btn doc-save " + (this.props.model.Dirty && !this.props.Uploading ? "" : "disabled")} onClick={this.save} >
            <div>Save</div>
          </div>
          }
          {!this.props.SaveQueue && <div className={"doc-btn doc-cancel " + (this.props.model.Dirty && !this.props.Uploading ? "" : "disabled")} onClick={this.cancel} >
            <div>Cancel</div>
          </div>}

          {this.props.CurrentEntity && this.props.CurrentEntity.CanDelete &&
            <div className={"doc-btn doc-delete " + ((!this.props.model.IsNew && !this.props.Uploading) ? "" : "disabled")} onClick={this.delete}>
              <div>Delete</div>
            </div>
          }
          {
            this.props.Uploading &&
            <div className="doc-saving-spinner">
              <div>Saving</div>
              <div><LoadingDotSpinner /></div>
            </div>
          }
          {this.props.model.DocumentId !== 'NewDocument' && this.props.model.Extension === 'pdf' && this.props.AllowPdfImport &&
            <div className={"doc-btn doc-import" + (this.state.ImportingPdf ? ' disabled' : '')} onClick={this.importPdfData}>
              <div>Import Data</div>
            </div>
          }
        </div>
        {
          this.state.FullScreenImage &&
          <div className="full-screen-rel">
            <div className="full-screen-abs">
              <div id="FullScreenImage">
                <div className="close-btn" onClick={this.closeFullScreenPhoto}>
                  <div className="cross-vertical"></div>
                  <div className="cross-horizontal"></div>
                </div>
                <div className="image-container"></div>
              </div>
            </div>
          </div>
        }
      </div>
    )
  }
}

export default connect(mapStateToProps)(EditItem);